import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { aboutUsLinks } from 'components/Layout/navigation';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import ArrowButton from 'components/Button/ArrowButton';
import Layout from 'components/Layout';
import TabNavigator from 'components/TabNavigator';
import { HeaderMedium } from 'components/typography';
import { MobileFilter } from 'components/BackgroundFilter';
import { WidthWrapper } from 'components/wrappers';
import { HeroSection } from 'components/wrappers';
import { BasicDiv } from 'components/wrappers';
import { TextDesc } from 'components/wrappers';
import HeroBg from 'images/local-vs-origin/local-vs-origin-bg.png';
import LocalVsOrigin from 'images/local-vs-origin/local-vs-origin-img.png';
import UserFriendlyImg from 'images/all-in-one/definitions.png';
import ListImage from 'images/local-vs-origin/list-image.png';
import ListImageCross from 'images/local-vs-origin/list-image-cross.png';
import AdvantagesDisadvantages from 'images/local-vs-origin/advantages-and-disadvantages.png';
import AdvantagesDisadvantages2 from 'images/local-vs-origin/advantages-and-disadvantages-2.png';
import LocalFulfillment from 'images/local-vs-origin/local-fulfillment.png';
import OriginFulfillment from 'images/local-vs-origin/origin-fulfillment.png';

import {UnorderedList} from 'components/blog/SingleBlogStyle';

import { DetailContent, PageContainer, MainSection, ShippingSectionURL, AllInOneSection }  from 'components/pages/Resources/LocalVsOrigin';

const ContentSmall = styled.p`
  position: relative;
  color: #4D4D4D;
  font-size: 15px;
  font-weight: 400;
  line-height:1.5;
  margin-bottom:25px;
`;

const ContentSmallTitle = styled.p`
  position: relative;
  color: #4D4D4D;
  font-size: 15px;
  font-weight: 400;
  line-height:1.5;
  margin-bottom:0px;
`;

const ContentSmallBg = styled.p`
  position: relative;
  color: #4D4D4D;
  font-size: 15px;
  font-weight: 400;
  line-height:1.5;
  margin-bottom:25px;
  background: #FCF1DE;
  text-align: center;
  border-bottom:2px solid #000;
`;

const ContentSmallCenter = styled.p`
  position: relative;
  color: #4D4D4D;
  font-size: 15px;
  font-weight: 400;
  line-height:1.5;
  margin-bottom:10px;
  text-align: center;
`;

const ContentSmallSpan = styled.span`
  position: relative;
  color: #4D4D4D;
  font-size: 15px;
  font-weight: 400;
  line-height:1.5;
`;

const Clear  = styled.div`
  clear:both;
  padding:0 !important;
`;

const Row  = styled.div`
  max-width:1300px;
  margin-left:auto;
  margin-right:auto;
`;

const TabNavigation = styled.div`
  width:33%;
  display:inline-block;
`;

const HeroHeader = styled.h1`
  font-size: 35px;
  margin-bottom: 20px;
  color:#fff
`;

const HeroText = styled.p`
  font-size: 14px;
  font-weight: 300;
  color: #fff;
  line-height:1.8;
`;
const Breadcrumbs = styled.p`
    padding: 0px 0px 25px 0px;
    color:#ffff
`;

const AllInOnePlatform = () => {
  const intl = useIntl()
  return (
    <Layout>
      <Helmet>
        {/* <title>Local Fulfillment vs Origin Fulfillment | Shipkoo</title> */}
        <title>{intl.formatMessage({ id: "resources-local-fulfillmemt" })}</title>
        <link rel="alternate" href={intl.formatMessage({ id: "lfvof-link" })} hreflang={intl.formatMessage({ id: "language" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "lfvof-link-alt" })} hreflang={intl.formatMessage({ id: "language-alt" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "lfvof-link-alt2" })} hreflang={intl.formatMessage({ id: "language-alt2" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "lfvof-link-alt3" })} hreflang={intl.formatMessage({ id: "language-alt3" })} />
        <meta name="description" content="" />
        <meta property="og:title" content={intl.formatMessage({ id: "resources-local-fulfillmemt" })} />
        <meta property="og:description" content="" />
        <meta name="twitter:title" content={intl.formatMessage({ id: "resources-local-fulfillmemt" })} />
        <meta name="twitter:description" content="" />
      </Helmet>
      <PageContainer>
        <MainSection>
          <div id="hero-section" className={intl.formatMessage({ id: "language" })}>
            <img className="hero-bg" src={HeroBg} alt="local fulfillment vs origin fulfillment"/>
            <Row>
                <div className="column-half">
                  <div className="content-container">
                    <Breadcrumbs className ="breadcrumb"><Link to="/">Home</Link> / {intl.formatMessage({ id: "menu-local-vs-origin" })}</Breadcrumbs>
                    <HeroHeader>{intl.formatMessage({ id: "lfvof-header" })}</HeroHeader>
                    <HeroText>{intl.formatMessage({ id: "lfvof-description" })}</HeroText>
                  </div>
                </div>
                <div className="column-half last">
                  <div className="content-container">
                    <img src={LocalVsOrigin} alt="local fulfillment vs origin fulfillment" />
                  </div>
                </div>
                <Clear></Clear>
            </Row>
          </div>  
        </MainSection>        
        <ShippingSectionURL>
          <Row>
            <div id="tab-navigation">
              <TabNavigation>
                <a href="#definitions">
                  <h3>{intl.formatMessage({ id: "lfvof-definitions" })}</h3>
                </a>
              </TabNavigation>
              <TabNavigation>
                <a href="#advantages-disadvantages">
                  <h3>{intl.formatMessage({ id: "lfvof-advantages-disadvantages" })}</h3>
                </a>
              </TabNavigation>
              <TabNavigation>
                <a href="#what-can-shipkoo-do">
                  <h3>{intl.formatMessage({ id: "lfvof-what-can-shipkoo-do" })}</h3>
                </a>
              </TabNavigation>
              <Clear></Clear>
            </div> 
          </Row>
        </ShippingSectionURL>
        <AllInOneSection>
          <div id="definitions">
            <Row>
              <div className="column-whole">
                <h2>
                  {intl.formatMessage({ id: "lfvof-definitions-title" })}
                </h2>
              </div>
              <Clear></Clear>
              <div className="column-half">
                <div className="definitions">
                  <img src={LocalFulfillment} className="platform-image" alt="local fulfillment"/>
                  <ContentSmallTitle>
                    <strong>{intl.formatMessage({ id: "lfvof-definitions-title-1" })}</strong>
                  </ContentSmallTitle>
                  <ContentSmall>
                    {intl.formatMessage({ id: "lfvof-definitions-desc-1" })}
                  </ContentSmall>
                </div>
              </div>
              <div className="column-half last">
                <div className="definitions">
                  <img src={OriginFulfillment} className="platform-image" alt="origin fulfillment" />
                  <ContentSmallTitle>
                    <strong>{intl.formatMessage({ id: "lfvof-definitions-title-2" })}</strong>
                  </ContentSmallTitle>
                  <ContentSmall>
                    {intl.formatMessage({ id: "lfvof-definitions-desc-2" })}
                  </ContentSmall>
                </div>
              </div>
              <Clear></Clear>
            </Row>
          </div>
        </AllInOneSection>   
        <AllInOneSection>
          <div id="advantages-disadvantages">
            <Row>
              <h2>
                {intl.formatMessage({ id: "lfvof-advantages-disadvantages-title" })}
              </h2>
              <div className="column-half whole">
                <ContentSmall>
                  <strong>{intl.formatMessage({ id: "lfvof-advantages-disadvantages-subtitle" })}</strong>
                </ContentSmall>
                <ContentSmall>
                  {intl.formatMessage({ id: "lfvof-advantages-disadvantages-desc-1" })}
                </ContentSmall>
                <ContentSmall>
                  {intl.formatMessage({ id: "lfvof-advantages-disadvantages-desc-2" })}
                </ContentSmall>
                <div className="column-half">
                  <UnorderedList>
                    <li>
                      <img src={ListImage} className="list-image" alt="list Icon" />{intl.formatMessage({ id: "lfvof-advantages-disadvantages-list-1" })}
                    </li>
                    <li>
                      <img src={ListImage} className="list-image" alt="list Icon" />{intl.formatMessage({ id: "lfvof-advantages-disadvantages-list-2" })}
                    </li>
                    <li>
                      <img src={ListImage} className="list-image" alt="list Icon" />{intl.formatMessage({ id: "lfvof-advantages-disadvantages-list-3" })}
                    </li>
                  </UnorderedList>
                </div>
                <div className="column-half last">
                  <UnorderedList>
                    <li>
                      <img src={ListImageCross} className="list-image" alt="list Icon" />{intl.formatMessage({ id: "lfvof-advantages-disadvantages-list-4" })}
                    </li>
                    <li>
                      <img src={ListImageCross} className="list-image" alt="list Icon" />{intl.formatMessage({ id: "lfvof-advantages-disadvantages-list-5" })}
                    </li>
                    <li>
                      <img src={ListImageCross} className="list-image" alt="list Icon" />{intl.formatMessage({ id: "lfvof-advantages-disadvantages-list-6" })}
                    </li>
                    <li>
                      <img src={ListImageCross} className="list-image" alt="list Icon" />{intl.formatMessage({ id: "lfvof-advantages-disadvantages-list-7" })}
                    </li>
                  </UnorderedList>
                </div>
              </div>
              <div className="column-half last whole">
                <img className="" src={AdvantagesDisadvantages} alt="advantages and disadvantages" />
              </div>
              <Clear></Clear>
            </Row>
            <Row>
              <div className="column-half last whole no-bottom">
                <img className="" src={AdvantagesDisadvantages2} alt="advantages and disadvantages" />
              </div>
              <div className="column-half whole no-bottom">
                <ContentSmall>
                  <strong>{intl.formatMessage({ id: "lfvof-advantages-disadvantages-subtitle-2" })}</strong>
                </ContentSmall>
                <ContentSmall>
                  {intl.formatMessage({ id: "lfvof-advantages-disadvantages-desc-3" })}
                </ContentSmall>
                <ContentSmall>
                  {intl.formatMessage({ id: "lfvof-advantages-disadvantages-desc-4" })}
                </ContentSmall>
                <div className="column-half">
                  <UnorderedList>
                    <li>
                      <img src={ListImage} className="list-image" alt="list Icon" />{intl.formatMessage({ id: "lfvof-advantages-disadvantages-list-8" })}
                    </li>
                    <li>
                      <img src={ListImage} className="list-image" alt="list Icon" />{intl.formatMessage({ id: "lfvof-advantages-disadvantages-list-9" })}
                    </li>
                    <li>
                      <img src={ListImage} className="list-image" alt="list Icon" />{intl.formatMessage({ id: "lfvof-advantages-disadvantages-list-10" })}
                    </li>
                    <li>
                      <img src={ListImage} className="list-image" alt="list Icon" />{intl.formatMessage({ id: "lfvof-advantages-disadvantages-list-11" })}
                    </li>
                    <li>
                      <img src={ListImage} className="list-image" alt="list Icon" />{intl.formatMessage({ id: "lfvof-advantages-disadvantages-list-12" })}
                    </li>
                  </UnorderedList>
                </div>
                <div className="column-half last">
                  <UnorderedList>
                    <li>
                      <img src={ListImageCross} className="list-image" alt="list Icon" />{intl.formatMessage({ id: "lfvof-advantages-disadvantages-list-13" })}
                    </li>
                    <li>
                      <img src={ListImageCross} className="list-image" alt="list Icon" />{intl.formatMessage({ id: "lfvof-advantages-disadvantages-list-14" })}
                    </li>
                    <li>
                      <img src={ListImageCross} className="list-image" alt="list Icon" />{intl.formatMessage({ id: "lfvof-advantages-disadvantages-list-15" })}
                    </li>
                  </UnorderedList>
                </div>
              </div>
              <Clear></Clear>
            </Row>
          </div>
        </AllInOneSection>
        <AllInOneSection>
          <div id="what-can-shipkoo-do" className={intl.formatMessage({ id: "language" })}>
            <Row>
              <h2>
                {intl.formatMessage({ id: "lfvof-what-can-shipkoo-do-title" })}
              </h2>
              <div className="column-whole">
                <ContentSmall>
                  {intl.formatMessage({ id: "lfvof-what-can-shipkoo-do-desc" })}
                </ContentSmall>
              </div>
              <Link to="/contact">
                <ArrowButton >{intl.formatMessage({ id: "drop-a-line-button" })}</ArrowButton>
              </Link>
              <Clear></Clear>
            </Row> 
          </div>
        </AllInOneSection>
      </PageContainer>
    </Layout>
  );
};

export default AllInOnePlatform;
